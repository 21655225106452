import {
  createContext,
  useState,
  useMemo,
  useContext,
  PropsWithChildren,
} from 'react';

import { TabContext } from '@mui/lab';

const TabsContext = createContext<TabsContextProps | undefined>(undefined);

export const useTabsContext = (): TabsContextProps => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error('useTabsContext must be inside a Tabs');
  }
  return context;
};

type TabsContextProps = {
  value: string;
  goTo: (value: string) => void;
  indicatorVariant: 'default' | 'large';
};

type TabsProviderProps = PropsWithChildren<{
  value: TabsContextProps['value'];
  indicatorVariant?: TabsContextProps['indicatorVariant'];
}>;

export const TabsProvider = ({
  children,
  value,
  indicatorVariant = 'default',
}: TabsProviderProps) => {
  const [currentValue, goTo] = useState(value);

  const context = useMemo(
    () => ({ value: currentValue, goTo, indicatorVariant }),
    [currentValue]
  );

  return (
    <TabsContext.Provider value={context}>
      <TabContext value={currentValue}>{children}</TabContext>
    </TabsContext.Provider>
  );
};

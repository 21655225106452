import { css } from '@emotion/react';
import { Box, Theme, Typography } from '@mui/material';
import { Types } from '@scorenco/core';

import {
  Markdown,
  SlideTabPanel,
  TabButton,
  TabList,
  TabPanel,
  TabsProvider,
  Wrapper,
} from '../../primitives';
import { StrapiImage } from '../../strapi';

const cs = (theme: Theme) => css`
  --dot-size: 0.75rem;
  --box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.07);
  --border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tabs-panel {
    flex: 3;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
  }

  .MuiTabs-indicator {
    left: 0;
    right: unset;
    background-color: ${theme.vars.palette.secondary.main};
    width: 3px;
  }

  .tabs {
    position: relative;
    margin-bottom: calc(var(--dot-size) + 3rem);
    box-shadow: var(--box-shadow);
    box-shadow: none;
    border-radius: var(--border-radius);
    overflow: visible;
    background: ${theme.vars.palette.background.paper};
    flex-direction: column-reverse;
    width: 50rem;
    @media (max-width: 50rem) {
      width: 90%;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-root {
    position: absolute;
    border: 0;
    background: transparent;
    bottom: calc(-1 * (var(--dot-size) + 3rem));
    left: 0;
    right: 0;
  }

  .MuiTabs-flexContainer {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .tabs-button {
    border: 0;
    color: ${theme.vars.palette.primary.main};
    background-color: ${theme.vars.palette.primary.main};
    border-radius: 9999px;
    flex: 0 0 var(--dot-size);
    height: var(--dot-size);
    min-height: var(--dot-size);
    margin: 0.25rem 1rem;
    transition: transfom 0.2s ease-in-out;
    box-shadow: var(--box-shadow);
    min-width: 0;
    padding: 0;

    &:hover {
      transform: scale(1.1);
    }

    &[aria-selected='true'] {
      transform: scale(1.2);
      color: ${theme.vars.palette.premium?.main};
      background-color: ${theme.vars.palette.premium?.main};
    }

    span {
      display: none;
    }
  }
`;

type TestimonialSliceProps = {
  slice: { children: Types.ComponentSlicesTestimonial[] };
};

export const TestimonialSlice = ({ slice }: TestimonialSliceProps) => {
  return (
    <Wrapper css={cs} color="body" marginY={8}>
      <TabsProvider value="0">
        <Box
          className="tabs"
          css={
            slice.children.length === 1 &&
            css`
              margin-bottom: 0 !important;
            `
          }
        >
          {slice.children.length > 1 && (
            <TabList orientation="vertical" sx={{ minWidth: '20rem' }}>
              {slice.children?.map((testimony, idx) => (
                <TabButton
                  key={idx}
                  className="tabs-button"
                  value={`${idx}`}
                  label={testimony?.name}
                />
              ))}
            </TabList>
          )}

          {slice.children?.map((testimony, idx) => (
            <SlideTabPanel
              nbTabs={slice.children?.length}
              key={idx}
              index={idx}
            >
              <TabPanel className="tabs-panel" value={`${idx}`}>
                <Box
                  css={(theme) => css`
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    height: 100%;

                    ${theme.breakpoints.down('md')} {
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    }
                  `}
                >
                  <StrapiImage
                    image={testimony?.image}
                    css={css`
                      flex: 0.5;
                      padding: 1rem;
                      max-height: 17rem;
                      max-width: 17rem;
                      object-fit: contain;
                    `}
                  />

                  <Box sx={{ flex: 1, padding: '1rem' }}>
                    <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
                      <Markdown value={testimony?.review} isInline={true} />
                    </Typography>
                    <Typography variant="body2" textAlign="left">
                      {testimony?.name}
                    </Typography>
                    <Typography variant="body2" textAlign="left">
                      {testimony?.role}
                    </Typography>
                  </Box>
                </Box>
              </TabPanel>
            </SlideTabPanel>
          ))}
        </Box>
      </TabsProvider>
    </Wrapper>
  );
};

import {
  TabPanel as MuiTabPanel,
  TabPanelProps as MuiTabPanelProps,
} from '@mui/lab';

export type TabPanelProps = MuiTabPanelProps;

export const TabPanel = ({ children, ...rest }: TabPanelProps) => {
  return <MuiTabPanel {...rest}>{children}</MuiTabPanel>;
};

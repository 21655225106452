import { PropsWithChildren } from 'react';

import { PanInfo, motion } from 'framer-motion';

import { useTabsContext } from './TabsContext';

type SlideTabPanelProps = PropsWithChildren<{
  nbTabs: number;
  index: number;
  disabled?: boolean;
}>;

export const SlideTabPanel = ({
  children,
  nbTabs,
  index,
  disabled = false,
  ...rest
}: SlideTabPanelProps) => {
  const { value, goTo } = useTabsContext();

  const handleDragEnd = (e: MouseEvent, info: PanInfo) => {
    const swipe = info.offset.x;
    const minSwipeDistance = 100;

    if (swipe > minSwipeDistance && Number(value) > 0) {
      goTo(String(Number(value) - 1));
    } else if (swipe < -minSwipeDistance && Number(value) < nbTabs - 1) {
      goTo(String(Number(value) + 1));
    }
  };

  if (nbTabs < 2 || disabled) {
    return <>{children}</>;
  }

  return (
    <motion.div
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={1}
      onDragEnd={handleDragEnd}
      initial={{ opacity: 0 }}
      animate={{
        opacity: Number(value) === index ? 1 : 0,
        transition: { duration: 0.5 },
      }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

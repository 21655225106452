import { css, useTheme } from '@emotion/react';
import {
  TabList as MuiTabList,
  TabListProps as MuiTabListProps,
} from '@mui/lab';

import { useTabsContext } from './TabsContext';

export type TabListProps = MuiTabListProps;

export const TabList = ({ children, orientation, ...rest }: TabListProps) => {
  const { goTo, indicatorVariant } = useTabsContext();
  const theme = useTheme();

  let customProps: TabListProps = {
    variant: 'scrollable',
    allowScrollButtonsMobile: true,
    scrollButtons: 'auto',
    css: css`
      max-width: 100%;
    `,
  };

  if (indicatorVariant === 'large') {
    customProps = {
      variant: 'scrollable',
      allowScrollButtonsMobile: true,
      scrollButtons: 'auto',
      css: [
        css`
          border-radius: 21px;
          min-height: 44px;
          background-color: ${theme.vars.palette.background.paper};
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.07);

          .MuiTabs-flexContainer {
            display: inline-flex;
            position: relative;
            z-index: 1;
          }

          .MuiTabs-indicator {
            background: none;

            ${orientation === 'vertical'
              ? css`
                  left: 3px;
                  right: 3px;
                  top: 3px;
                  width: auto;
                `
              : css`
                  top: 3px;
                  bottom: 3px;
                  right: 3px;
                  height: auto;
                `}

            &:after {
              content: ' ';
              display: block;
              position: absolute;
              border-radius: 22px;
              background-color: ${theme.vars.palette.primary.main};

              ${orientation === 'vertical'
                ? css`
                    top: 4px;
                    left: 0;
                    right: 0;
                    bottom: 4px;
                  `
                : css`
                    top: 0;
                    left: 4px;
                    right: 4px;
                    bottom: 0;
                  `}
            }
          }
        `,
      ],
    };
  }

  return (
    <MuiTabList
      textColor="secondary"
      indicatorColor="secondary"
      onChange={(event, value) => {
        goTo(value);
      }}
      orientation={orientation}
      {...customProps}
      {...rest}
    >
      {children}
    </MuiTabList>
  );
};

import { css } from '@emotion/react';
import { Tab, TabProps } from '@mui/material';

import { useTabsContext } from './TabsContext';

export type TabButtonProps = TabProps;

export const TabButton = ({ children, ...rest }: TabButtonProps) => {
  const { indicatorVariant } = useTabsContext();

  let customProps: TabButtonProps = {};

  if (indicatorVariant === 'large') {
    customProps = {
      disableRipple: true,
      css: (theme) =>
        css`
          min-height: 44px;
          min-width: 96px;
          opacity: 0.7;
          text-transform: initial;
          color: ${theme.vars.palette.text.primary};

          &:hover {
            opacity: 1;
          }

          &.Mui-selected {
            opacity: 1;
            color: ${theme.vars.palette.primary.contrastText};
          }
        `,
    };
  }

  return (
    <Tab {...customProps} {...rest}>
      {children}
    </Tab>
  );
};
